import React from 'react';
import styled from 'styled-components';


const FloatingButton = styled.button`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #128C7E;
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.95);
  }
`;



const FloatingWhatsAppButton = () => {
  const handleClick = () => {
    // Replace with your WhatsApp number (include country code without '+' or '0')
    const phoneNumber = '+966533260333';
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <FloatingButton onClick={handleClick} aria-label="Chat via WhatsApp">
      <img src={process.env.PUBLIC_URL + '/static/img/whatsapp2.svg'}  style={{ height: '45px' }}  />
    </FloatingButton>
  );
};

export default FloatingWhatsAppButton;
