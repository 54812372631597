import styled from 'styled-components';

export const Section = styled.section`
  width: 100%;
  background-color: white;
  padding: 1.75rem 0;

  @media (min-width: 768px) {
    padding: 5rem 0 6rem;
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  margin: 0 auto;
  line-height: 1.5;
  color: black;
  max-width: 80rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const ImageContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 0 1rem;
  background-repeat: no-repeat;
  background-size: contain;
  @media (min-width: 1024px) {
    width: 50%;
    }
  }
`;

export const ContentContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  order: ${props => props.reversed ? '-1' : '0'};

  @media (min-width: 768px) {
    width: 50%;
    padding-left: ${props => props.reversed ? '1.5rem' : '2.5rem'};
    order: 0;

    ${props => props.reversed && `
      @media (min-width: 1280px) {
        padding-left: 8rem;
        padding-right: 8rem;
      }
    `}
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.875rem;
  }
`;

export const Text = styled.p`
  padding: 1rem 0 2rem;
  margin: 0;
  line-height: 1.75;
  color: #374151;

  @media (min-width: 1024px) {
    font-size: 1.125rem;
  }
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  line-height: 1.5;
`;

export const ListItem = styled.li`
  box-sizing: border-box;
  position: relative;
  padding: 0.25rem 0;
  padding-left: 0;
  color: #6b7280;
`;

export const CheckIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: white;
  background-color: #2e6287;
  border-radius: 9999px;

  span {
    font-size: 0.875rem;
    font-weight: 700;
  }
`;
