import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import HomeCarousel from "./HomeCarousel";




const LandingHeroSection = () => {
  const cities = [
    {
      label: 'الرياض',
      bounds: '25.1564723554458,47.34695425572672,24.29391133583597,46.29810330633227',
      address: 'Riyadh Saudi Arabia'
    },
    {
      label: 'جدة',
      bounds: '21.700485,39.309059,21.285407,39.002874',
      address: 'Jeddah Saudi Arabia'
    },
    {
      label: 'مكة المكرمة',
      bounds: '22.34235016,40.55529572,20.51182105,39.07214143',
      address: 'Makkah Saudi Arabia'
    },
    {
      label: 'المدينة المنورة',
      bounds: '24.65918629927064,39.86114497265746,24.28295853665836,39.37396997540959', // NE to SW
      address: 'Madinah Saudi Arabia'
    },

    // الخبر (Al Khobar)
    {
      label: 'الخبر',
      bounds: '26.37099006430287,50.23931984508061,26.0274786381363,50.09078973650816', // NE to SW
      address: 'Khobar Saudi Arabia'
    },

    // الدمام (Dammam)
    {
      label: 'الدمام',
      bounds: '26.54783813391984,50.23900712811979,26.1772886863971,49.74607388193249', // NE to SW
      address: 'Dammam Saudi Arabia'
    }
  ];

  const propertyTypes = {
    villa: 'Villas',
    chalets: 'Chalets',
    office: 'Offices',
    camps: 'Camps',
    furnishedApartments: 'Furnished-Apartments',
    resorts: 'Resorts',
    eventVenues: 'Event-Venues',
    officeSpaces: 'Office-Spaces',
    restHouses: 'Rest-Houses',
    meetingOffices: 'meeting-offices'
  };

  const [searchParams, setSearchParams] = useState({
    bounds: '',
    pub_categoryLevel1: '',
    price: ''
  });

  const handleSearch = (e) => {
    e.preventDefault();
    const params = new URLSearchParams();

    if (searchParams.bounds && searchParams.address) {
      params.append('address', encodeURIComponent(searchParams.address));
      params.append('bounds', searchParams.bounds);
      params.append('mapSearch', 'true');
    }

    if (searchParams.pub_categoryLevel1) {
      params.append('pub_categoryLevel1', searchParams.pub_categoryLevel1);
    }

    if (searchParams.price) {
      params.append('price', searchParams.price);
    }

    window.location.href = `/s?${params.toString()}`;
  };

  const handleCityChange = (selectedBounds, selectedAddress) => {
    setSearchParams(prev => ({
      ...prev,
      bounds: selectedBounds,
      address: selectedAddress
    }));
  };

  const handlePropertyTypeChange = (value) => {
    setSearchParams(prev => ({
      ...prev,
      pub_categoryLevel1: value
    }));
  };

  const handlePriceChange = (range) => {
    let priceValue = '';
    switch(range) {
      case '0-100':
        priceValue = '0,100';
        break;
      case '100-200':
        priceValue = '100,200';
        break;
      case '200+':
        priceValue = '200,500'; // Adjust max price as needed
        break;
      default:
        priceValue = '';
    }
    setSearchParams(prev => ({
      ...prev,
      price: priceValue
    }));
  };

  const images = [
    // Modern apartment interior
    'https://images.unsplash.com/photo-1564013799919-ab600027ffc6?w=800&q=80',

    // Cozy living room
    'https://images.unsplash.com/photo-1600585154526-990dced4db0d?w=800&q=80',

    // Luxury villa exterior
    'https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?w=800&q=80',

    // Vacation rental bedroom
    'https://images.unsplash.com/photo-1602941525421-8f8b81d3edbb?w=800&q=80',

    // City apartment building
    'https://images.unsplash.com/photo-1502005229762-cf1b2da7c5d6?w=800&q=80',

    // Mountain cabin rental
    'https://images.unsplash.com/photo-1601918774946-25832a4be0d6?w=800&q=80',

    // Beach house exterior
    'https://images.unsplash.com/photo-1512917774080-9991f1c4c750?w=800&q=80',

    // Modern kitchen in rental
    'https://images.unsplash.com/photo-1556911220-bff31c812dba?w=800&q=80',

    // Co-working space in rental
    'https://images.unsplash.com/photo-1598928636135-d146006ff4be?w=800&q=80',

    // Luxury bathroom
    'https://images.unsplash.com/photo-1584622650111-993a426fbf0a?w=800&q=80'
  ];

  return (
    <Wrapper>
      {/*<Navigation />*/}

      <MainContainer>
        <TopBanner>
          <BannerBadge>
           اكثر من 1,000 عقار جاري تجهيزه لخدمتك
          </BannerBadge>
        </TopBanner>

        <ContentWrapper>
          <Heading>
            ساعتك أبرك
            مع ساعة واكثر
          </Heading>
          <SubText>
             فخورون بتقديم خدماتنا في العديد من المواقع الرئيسية، مما يضمن لك المرونة أينما كنت
          </SubText>

          <SubText2>احجز ساعتك الآن</SubText2>

        </ContentWrapper>

        <SearchForm onSubmit={handleSearch}>
          <SelectInput
            value={searchParams.address}
            onChange={(e) => {
              const selectedCity = cities.find(city =>
                city.address === e.target.value
              );
              if (selectedCity) {
                handleCityChange(
                  selectedCity.bounds,
                  selectedCity.address
                );
              }
            }}
          >
            <option value=""> المدينة</option>
            {cities.map(city => (
              <option
                key={city.address}
                value={city.address}
              >
                {city.label}
              </option>
            ))}
          </SelectInput>

          <SelectInput
            value={searchParams.pub_categoryLevel1}
            onChange={(e) => handlePropertyTypeChange(e.target.value)}
          >
            <option value=""> النوع ، شقق ، مكاتب ...</option>
            {Object.entries(propertyTypes).map(([key, value]) => (
              <option key={key} value={value}>
                {{
                  'Villas': 'فلل',
                  'Chalets': 'شاليهات',
                  'Offices': 'مكاتب',
                  'Camps': 'مخيمات',
                  'Furnished-Apartments': 'شقق مفروشة',
                  'Resorts': 'منتجعات',
                  'Event-Venues': 'قاعات فعاليات',
                  'Office-Spaces': 'مساحات مكتبية',
                  'Rest-Houses': 'استراحات',
                  'meeting-offices': 'مكاتب اجتماعات'
                }[value]}
              </option>
            ))}
          </SelectInput>

          <SelectInput
            value={searchParams.price}
            onChange={(e) => handlePriceChange(e.target.value)}
          >
            <option value=""> السعر</option>
            <option value="0-100000">حتى 100 ر.س</option>
            <option value="100000-200000">200 - 100 ر.س</option>
            <option value="2000000+">أكثر من 200 ر.س</option>
          </SelectInput>

          <SearchButton type="submit">
            بحث
          </SearchButton>
        </SearchForm>
        <HomeCarousel images={images} />


      </MainContainer>
    </Wrapper>
  );
};

const Navigation = () => (
  <NavBar>
    <BrandLink href="#">
      <LogoIcon />
      <BrandName>BrandLyft</BrandName>
    </BrandLink>

    <NavLinks>
      {['Brands', 'Creators', 'Pricing', 'Use Cases', 'Contact'].map((link) => (
        <NavLink key={link} href="#">{link}</NavLink>
      ))}
    </NavLinks>

    <AuthButtons>
      <LoginButton>Log in</LoginButton>
      <SignupButton>Sign up</SignupButton>
    </AuthButtons>
  </NavBar>
);

// Styled components
//background-image: url('/static/img/elegant.jpg');
const Wrapper = styled.div`
  background-repeat: no-repeat;
  background-color: white;
`;

const NavBar = styled.nav`
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
`;

const BrandLink = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
`;

const LogoIcon = styled.svg.attrs({
  viewBox: '0 0 24 24',
  children: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"/>
})`
  width: 1.5rem;
  height: 1.5rem;
  fill: none;
  stroke: currentColor;
`;

const BrandName = styled.span`
  font-weight: 700;
  font-size: 1.25rem;
`;

const NavLinks = styled.div`
  display: none;
  gap: 2rem;

  @media (min-width: 768px) {
    display: flex;
  }
`;

const NavLink = styled.a`
  position: relative;
  padding: 0.5rem 0;
  color: inherit;
  text-decoration: none;

  &:hover:after {
    width: 100%;
    text-decoration:none !important;
  }
  &:hover{
    text-decoration:none !important;
  }
`;

const AuthButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Button = styled.button`
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
`;

const LoginButton = styled(Button)`
  padding: 0.5rem 1rem;
  color: #4b5563;

  &:hover {
    color: #1f2937;
  }
`;

const SignupButton = styled(Button)`
  padding: 0.5rem 1.5rem;
  background-color: #1f2937;
  color: white;
  border-radius: 9999px;
  &:hover {
    background-color: #374151;
    transform: scale(1.05);
  }
`;

const MainContainer = styled.main`
  position: relative;
  padding-top: 3rem;
  overflow: hidden;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const TopBanner = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  margin-top: 2rem;
`;

const BannerBadge = styled.span`
  background-color: #b3dae9;
  color: #184657;
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: Dubai;
`;

const ContentWrapper = styled.div`
  text-align: center;
  max-width: 56rem;
  margin: 0 auto;
`;

const Heading = styled.h1`
  font-size: 4rem;
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 2rem;
  letter-spacing: -0.025em;
  font-family: Dubai;
  background-image: linear-gradient(45deg, #ff6b6b, #4ecdc4);
  -webkit-background-clip: text;
  color: transparent;
`;

const SubText = styled.p`
  font-size: 1.25rem;
  color: #4b5563;
  margin-bottom: 3rem;
  max-width: 42rem;
  margin: 0 auto;
  line-height: 1.625;
`;

const SubText2 = styled.p`
  font-size: 1.25rem;
  color: #2d6479;
  max-width: 14rem;
  margin: 0 auto;
  line-height: 1.625;
  margin-top: 50px;
  background-color: #b3dae9;
  color: #184657;
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: Dubai;

`;




const CTAButton = styled(Button)`
  background: #2d6479;
  color: white;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  font-size: 1.125rem;
  font-weight: 500;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  margin: 3rem auto;
  display: block;

  &:hover {
    transform: translateY(-1px);
  }
`;


const SearchForm = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  max-width: 900px;
  margin: 2rem auto;
  border: 1px solid #a1d7eb;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    border-radius: 1rem;
    padding: 1.5rem;
    gap: 0.75rem;
  }

  @media (max-width: 480px) {
    padding: 1rem;
    margin: 1rem auto;
  }
`;

const SearchInput = styled.input`
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 1.5rem;
  font-size: 1rem;
  text-align: right;
  direction: rtl;
  background: #f8fafc;
  width: 100%;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #2d6479;
  }

  @media (max-width: 768px) {
    padding: 0.75rem 1.25rem;
    font-size: 0.9rem;
  }
`;

const SelectInput = styled.select`
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 1.5rem;
  font-size: 1rem;
  background: #f8fafc;
  cursor: pointer;
  text-align: right;
  direction: rtl;
  appearance: none;
  width: 100%;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #2d6479;
  }

  @media (max-width: 768px) {
    padding: 0.75rem 1.25rem;
    font-size: 0.9rem;
  }
`;

const SearchButton = styled.button`
  padding: 0.8rem 2rem;
  background: #2d6479;
  color: white;
  border: none;
  border-radius: 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  width: 100%;
  grid-column: 1 / -1;

  &:hover {
    background: #184657;
    transform: translateY(-1px);
  }

  @media (min-width: 769px) {
    grid-column: span 1;
    width: auto;
  }

  @media (max-width: 768px) {
    padding: 0.75rem 1.5rem;
    font-size: 0.9rem;
  }
`;
export default LandingHeroSection;
