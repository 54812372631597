import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListings } from '../../ducks/listing.duck';
import './AllListingsPage.css'
import ListingCard from "./ListingCard";
import SeApp from "../My/WebsiteSectionsRow";
const AllListingsPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchListings());
  }, [dispatch]);
  const listings = useSelector(state => state.marketplaceData.listings);

  return (
    <div className="all-listings-page">
      <h1 className="all-listings-title">الخدمات</h1>
      <SeApp />
      <div className="listings-grid">
        {listings.map(listing => (
          <ListingCard key={listing.id.uuid} listing={listing} />
        ))}
      </div>
    </div>
  );


};

export default AllListingsPage;
