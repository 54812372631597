import styled, { createGlobalStyle } from 'styled-components';



const Header = styled.header`
  min-height: 100vh;
  background-color: #f7fafc;
  padding: 2.5rem 1.25rem;

  @media (min-width: 1280px) {
    padding: 0;
  }
`;

const Container = styled.div`
  min-height: 100vh;
  width: 91.666667%;
  max-width: 1536px;
  margin: 0 auto;

  @media (min-width: 1280px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    place-items: center;
    gap: 3.5rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 36rem;
  margin: 0 auto;

  @media (min-width: 1280px) {
    max-width: 42rem;
    margin-top: -2rem;
  }
`;

const Heading = styled.h1`
  font-size: 1.875rem;
  font-weight: 600;
  color: #1a202c;
  line-height: 1.375;

  @media (min-width: 1280px) {
    font-size: 3rem;
    line-height: 1.375;
  }

  @media (min-width: 1536px) {
    font-size: 3.75rem;
  }
`;

const PurpleText = styled.span`
  color: #9f7aea;
`;

const Text = styled.p`
  line-height: 1.75rem;
  font-size: 1rem;
  margin: 0.75rem 0 2.5rem;

  @media (min-width: 1280px) {
    font-size: 1.125rem;
    line-height: 2.25rem;
  }
`;

const Button = styled.button`
  background-color: #9f7aea;
  color: white;
  border-radius: 0.5rem;
  padding: 0.875rem 1.25rem;
  letter-spacing: 0.05em;
  font-size: 0.875rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: #553c9a;
  }
`;

const LearnMoreButton = styled(Button).attrs({ as: 'a' })`
  background-color: transparent;
  color: inherit;
  text-transform: capitalize;
  margin-left: 1.25rem;
  padding: 0;
`;

const StatsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 24rem;
  margin: 4rem auto 0;
`;

const StatItem = styled.div`
  text-align: center;
`;

const StatNumber = styled.span`
  font-size: 1.875rem;
  font-weight: 600;
  color: #1a202c;
`;

const StatText = styled.p`
  text-transform: capitalize;
  margin-top: 0.5rem;
`;

const Divider = styled.div`
  width: 1px;
  height: 3rem;
  background-color: #1a202c;
`;

// Card Grid Components
const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 17.5rem;
  place-content: center;
  gap: 2em;
  margin-top: 3.5rem;

  @media (min-width: 1280px) {
    margin-top: 0;
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
    grid-template-rows: 7.375em 12em 7.375em;
    column-gap: 2em;
    row-gap: 2.1em;
  }
`;

const Card = styled.div`
  background: white;
  border-radius: 1.5rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
  padding: 1rem 1.25rem;
  max-width: 20rem;

  ${props => props.$variant === '1' && `
    align-self: start;
    padding-top: 1rem;
    padding-bottom: 2rem;
  `}

  ${props => props.$variant === '2' && `
    align-self: start;
    display: flex;
    align-items: center;
    width: 20rem;
    padding: 1.25rem;
  `}

  ${props => props.$variant === '3' && `
    align-self: start;
    padding-top: 1rem;
    padding-bottom: 2rem;
  `}

  ${props => props.$variant === '4' && `
    align-self: end;
    display: flex;
    align-items: center;
    width: 20rem;
    padding: 1.25rem;
  `}
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 10rem;

  @media (min-width: 1024px) {
    height: 12rem;
  }

  @media (min-width: 1280px) {
    height: 14rem;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
`;

const StarIcon = () => (
  <svg className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
);

// Usage in your component
const HeroTwoSection = () => (
  <>
    <Header>
      <Container>
        <ContentWrapper>
          <Heading>
            Get Amazing <PurpleText>Painting</PurpleText> for your Business
          </Heading>
          <Text>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Duis bibendum,
            lectus ut viverra rhoncus, dolor nunc faucibus libero.
          </Text>
          <Button>Subscribe</Button>
          <LearnMoreButton>learn more</LearnMoreButton>
          <StatsWrapper>
            <StatItem>
              <StatNumber>60K+</StatNumber>
              <StatText>painting collection</StatText>
            </StatItem>
            <Divider />
            <StatItem>
              <StatNumber>200+</StatNumber>
              <StatText>different category</StatText>
            </StatItem>
          </StatsWrapper>
        </ContentWrapper>

        <CardGrid>
          {/* Card 1 */}
          <Card $variant="1">
            <ImageWrapper>
              <CardImage src="https://images.pexels.com/photos/33041/antelope-canyon-lower-canyon-arizona.jpg" alt="Antelope Canyon" />
            </ImageWrapper>
            <h2>Antelope Canyon</h2>
            {/*<p>Elise Doe <span><StarIcon />5.0</span></p>*/}
          </Card>

          {/* Card 2 */}
          <Card $variant="2">
            <ImageWrapper>
              <CardImage src="https://images.pexels.com/photos/573299/pexels-photo-573299.jpeg" alt="Elise Doe" />
            </ImageWrapper>
            {/* ... other card content */}
          </Card>

          {/* Repeat for other cards */}
        </CardGrid>
      </Container>
    </Header>
  </>
);

export default HeroTwoSection;
