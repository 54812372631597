import styled from 'styled-components';
import { Section, Container, ImageContainer, ContentContainer, Title, Text, List, ListItem, CheckIcon }
  from './style';
import ShareTribeImage from "./ShareTribeImage"; // Import your shared styles

const DataTwoSections = [
  {
    image: process.env.PUBLIC_URL + '/static/img/sec1.png',
    title: 'تبحث عن ايجار بالساعة',
    text: 'وصلت ، نوفر لكل مرونة الاختيار وفخامة الإقامة للوقت الذي تحتاجه فقط في افضل الأماكن',
    items: [
      'مرونة الحجز والإالغاء',
      'إمكانية التمديد',
      'وسائل دفع آمنة'
    ],
    isReversed: false
  },
  {
    image: process.env.PUBLIC_URL + '/static/img/sec2.png',
    title: 'عندك عقار جاهز ؟',
    text: 'استغل الوقت الضائع وضاعف ايراداتك معنا ',
    items: [
      'اسعار تنافسية',
      'خيارات القبول والرفض والمراجعة',
      'وسائل دفع آمنة'
    ],
    isReversed: true
  }
];

const FeatureSection = () => {
  return (
    <Section>
      {DataTwoSections.map((section, index) => (
        <Container key={index}>
          {section.isReversed && (
            <ImageContainer className="right">
              <ShareTribeImage src={section.image} alt={section.title} loading="lazy" />
            </ImageContainer>
          )}

          <ContentContainer reversed={section.isReversed}>
            <Title>{section.title}</Title>
            <Text>{section.text}</Text>
            <List>
              {section.items.map((item, i) => (
                <ListItem key={i}>
                  <CheckIcon>
                    <span>✓</span>
                  </CheckIcon>
                  {item}
                </ListItem>
              ))}
            </List>
          </ContentContainer>

          {!section.isReversed && (
            <ImageContainer className="left">
              <ShareTribeImage src={section.image} alt={section.title} loading="lazy" />
            </ImageContainer>
          )}
        </Container>
      ))}
    </Section>
  );
};

export default FeatureSection;
