import React from 'react';
import { Link } from 'react-router-dom';
import './ListingCard.css';


const ListingCard = ({ listing }) => {
  // Safely access nested properties with optional chaining
  //const mainImage = listing.images?.[0]?.attributes?.variants?.['landscape-crop']?.url;
  const mainImage = listing.relationships?.images?.data?.[0]?.id?.uuid;
  const price = listing.attributes?.price;
  const publicData = listing.attributes?.publicData;
  const title = listing.attributes?.title;
  const description = listing.attributes?.description;

    console.log('************************')
   //console.log(listing.relationships?.images?.data?.[0]?.id?.uuid)
    console.log(listing)
    console.log('************************')

  // Handle missing data
  if (!listing || !listing.attributes) return null;

  return (
    <div className="listing-card">
      <Link to={`/l/no-slug/${listing.id.uuid}`} className="listing-card-link">
        <div className="image-container">
          <img
            src={listing.imageUrls[0]}
            alt={title}
            onError={(e) => {
              e.target.src = 'https://placehold.co/600x400';
            }}
            className="listing-image"
          />
        </div>

        <div className="listing-content">
          <h3 className="listing-title">{title}</h3>

          {listing.reviews.length > 0 && (
            <div className="reviews-container">
              <div className="rating-summary">
                <div className="stars">
                  {[...Array(5)].map((_, i) => (
                    <span key={i} className={`star ${i < Math.round(listing.averageRating) ? 'filled' : ''}`}>★</span>
                  ))}
                  <span className="review-count"> ({listing.reviews.length}) تقييم </span>
                </div>

              </div>

              {/*<div className="reviews-list">*/}
              {/*  {listing.reviews.slice(0, 2).map(review => ( // Show top 2 reviews*/}
              {/*    <div key={review.id} className="review-item">*/}
              {/*      <div className="review-rating">{'★'.repeat(review.rating)}</div>*/}
              {/*      {review.comment && <p className="review-text">{review.comment}</p>}*/}
              {/*    </div>*/}
              {/*  ))}*/}
              {/*</div>*/}
            </div>
          )}

          {publicData?.location?.address && (
            <div className="listing-location">
              {publicData.location.address}
              {publicData.location.address.length > 1
                ? `${publicData.location.address.substring(0, 1)}...`
                : publicData.location.address}
            </div>
          )}

          {price && (
            <div className="listing-price">
              {(price.amount/100).toLocaleString() + '.00 '}
              <img src={process.env.PUBLIC_URL+'/static/img/sar.png'} style={{ height: '13px',width: '13px'}}/>
              <span style={{ fontSize: '15px'}}> للساعة </span>
            </div>
          )}

          {/*{publicData?.categoryLevel1 && (*/}
          {/*  <div className="listing-category">*/}
          {/*    {publicData.categoryLevel1}*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*{description && (*/}
          {/*  <p className="listing-description">*/}
          {/*    {description.length > 100*/}
          {/*      ? `${description.substring(0, 100)}...`*/}
          {/*      : description}*/}
          {/*  </p>*/}
          {/*)}*/}

          <div className="listing-features">
            {publicData?.bathRooms?.length > 0 && (
              <span>
                <img src="/static/img/bathroom.png" style={{ width: '23px',
                  height: '21px',}}/>
                {publicData.bathRooms.length}
              </span>
            )}

            {publicData?.roomsNumber?.length > 0 && (
              <span>
                <img src="/static/img/bedroom.png" style={{ width: '35px',
                  height: '30px',}}/>
                {publicData.roomsNumber.length}
              </span>
            )}

          </div>
        </div>
      </Link>
    </div>
  );
};

export default ListingCard;
