import React from 'react';
import styled from 'styled-components';

const SquaresGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 1.5rem;
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  grid-auto-rows: min-content;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.2rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
`;

const SquareContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
`;

const ImageLink = styled.a`
  width: 100%;
  aspect-ratio: 1/1;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0,0,0,0.1);
    border-color: #2d6479;

    img {
      transform: scale(1.1);
    }
  }
`;

const SquareImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  transition: transform 0.3s ease;
  padding: 8px;
`;

const Label = styled.span`
  font-size: 0.95rem;
  font-weight: 500;
  color: #333;
  text-align: center;
  line-height: 1.3;
  max-width: 100px;

  @media (max-width: 768px) {
    font-size: 0.85rem;
    max-width: 80px;
  }
`;

// Reusable component
const WebsiteSectionsGrid = ({ sections }) => {
  return (
    <SquaresGrid>
      {sections.map((section, index) => (
        <SquareContainer key={index}>
          <ImageLink
            href={section.url}
            aria-label={`Navigate to ${section.label} section`}
          >
            <SquareImage
              src={section.imageUrl}
              alt={section.label}
              loading="lazy"
            />
          </ImageLink>
          <Label>{section.label}</Label>
        </SquareContainer>
      ))}
    </SquaresGrid>
  );
};

// Usage example
const SeApp = () => {
  const websiteSections = [
    {
      imageUrl: `${process.env.PUBLIC_URL}` + '/static/img/sec/Hotels.svg',
      label: 'فنادق',
      url: '/s?pub_categoryLevel1=Hotels'
    },
    {
      imageUrl: `${process.env.PUBLIC_URL}` + '/static/img/sec/appartment.svg',
      label: 'وحدات مخدومة',
      url: '/s?pub_categoryLevel1=Furnished-Apartments'
    },
    {
      imageUrl: `${process.env.PUBLIC_URL}` + '/static/img/sec/villa.svg',
      label: 'شاليهات',
      url: '/s?pub_categoryLevel1=Chalets'
    },
    {
      imageUrl: `${process.env.PUBLIC_URL}` + '/static/img/sec/farm.svg',
      label: 'مزارع واستراحات',
      url: '/s?pub_categoryLevel1=Camps'
    },
    {
      imageUrl: `${process.env.PUBLIC_URL}` + '/static/img/sec/cafe.svg',
      label: 'جلسات',
      url: '/s?pub_categoryLevel1=Rest-Houses'
    },
    {
      imageUrl: `${process.env.PUBLIC_URL}` + '/static/img/sec/football.svg',
      label: 'نوادي وملاعب',
      url: '/s?pub_categoryLevel1=playgrounds'
    },
    {
      imageUrl: `${process.env.PUBLIC_URL}` + '/static/img/sec/conference.svg',
      label: 'مساحات عمل',
      url: '/s?pub_categoryLevel1=meeting-offices'
    },
    {
      imageUrl: `${process.env.PUBLIC_URL}` + '/static/img/sec/stable.svg',
      label: 'اسطبل خيل',
      url: '/s?pub_categoryLevel1=stable'
    },
    {
      imageUrl: `${process.env.PUBLIC_URL}` + '/static/img/sec/lounges.svg',
      label: 'لاونجات',
      url: '/s?pub_categoryLevel1=lounges'
    },
    {
      imageUrl: `${process.env.PUBLIC_URL}` + '/static/img/sec/restaurants.svg',
      label: 'مطاعم',
      url: '/s?pub_categoryLevel1=restaurants'
    }
  ];

  return <WebsiteSectionsGrid sections={websiteSections} />;
};

export default SeApp;
