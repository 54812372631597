import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const HomeCarousel = ({ images }) => {
  const trackRef = useRef(null);
  const position = useRef(0);
  const speed = useRef(0.5);
  const [isHovered, setIsHovered] = useState(false);
  const animationId = useRef(null);

  useEffect(() => {
    speed.current = isHovered ? 0 : 0.5;
  }, [isHovered]);

  useEffect(() => {
    const animate = () => {
      if (!trackRef.current) return;

      position.current -= speed.current;

      if (position.current < -320) {
        position.current = 0;
        const firstChild = trackRef.current.firstElementChild;
        trackRef.current.appendChild(firstChild);
      }

      trackRef.current.style.transform = `rotate(-5deg) translateX(${position.current}px)`;
      animationId.current = requestAnimationFrame(animate);
    };

    animationId.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationId.current);
  }, []);

  return (
    <CarouselWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CarouselContainer>
        <CarouselTrack ref={trackRef}>
          {images.map((img, index) => (
            <CarouselCard key={`${img}-${index}`}>
              <CarouselImage src={img} alt={`Slide ${index + 1}`} />
            </CarouselCard>
          ))}
        </CarouselTrack>
      </CarouselContainer>
    </CarouselWrapper>
  );
};

// Styled components (same as previous implementation)
const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 380px;
  margin-top: 4rem;
`;

const CarouselContainer = styled.div`
  position: absolute;
  width: 200%;
  left: 50%;
  transform: translateX(-50%);
  height: 800px;
  border-radius: 50%;
  bottom: -420px;
  perspective: 1000px;
`;

const CarouselTrack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1.5rem;
  padding: 0 calc(25% - 150px);
  align-items: flex-start;
  transform-origin: center center;
`;

const CarouselCard = styled.div`
  flex: 0 0 300px;
  height: 380px;
  transform-origin: center bottom;
  transition: all 0.5s ease;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.1);
  transition: transform 0.5s ease;

  &:hover {
    transform: scale(1.15);
  }
`;

export default HomeCarousel;
