import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import classNames from 'classnames';
import css from './SectionTestimonial.module.css'; // Create corresponding CSS module

const SectionTestimonial = props => {
  const { defaultClasses, sectionId, testimonials } = props;

  return (
    <section id={sectionId} className={css.root}>
      <div className={classNames(defaultClasses.sectionDetails, css.content)}>
        <h2 className={defaultClasses.title}>Testimonials</h2>
        <div className={css.testimonialList}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className={css.testimonialItem}>
              <p className={css.quote}>"{testimonial.quote}"</p>
              <p className={css.author}>- {testimonial.author}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

SectionTestimonial.propTypes = {
  sectionId: string.isRequired,
  testimonials: arrayOf(shape({
    quote: string.isRequired,
    author: string.isRequired,
  })).isRequired,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
  }),
};

export default SectionTestimonial;
