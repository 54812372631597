const {useState, useEffect} = require("react");
const ShareTribeImage = ({ src, alt }) => {
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    // Try multiple path resolutions
    const pathsToTry = [
      `${window.location.origin}${process.env.PUBLIC_URL}${src}`,
      `${window.location.origin}${src}`,
      `${process.env.PUBLIC_URL}${src}`,
      src
    ];

    const testImage = (path, index) => {
      const img = new Image();
      img.src = path;
      img.onload = () => setImgSrc(path);
      img.onerror = () => {
        if (index < pathsToTry.length - 1) {
          testImage(pathsToTry[index + 1], index + 1);
        } else {
          console.error('All image paths failed:', pathsToTry);
          setImgSrc('/static/img/fallback.png');
        }
      };
    };

    testImage(pathsToTry[0], 0);
  }, [src]);

  return (
    <img
      src={imgSrc}
      alt={alt}
      loading="lazy"
      style={{
        width: '100%',
        height: 'auto', // Maintain aspect ratio
        maxWidth: '100%', // Never exceed container width
        display: 'block', // Remove extra space below image
        margin: '0 auto', // Center if narrower than container
        opacity: imgSrc ? 1 : 0,
        transition: 'opacity 0.3s ease'
      }}
    />
  );
};

export default ShareTribeImage;
